import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import Footer from "./Footer";
const MarketPlace = () => {
  return (
    <>
      <main className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Box
                sx={{
                  py: 1,
                  px: 10,
                }}
              >

              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  justifyContent: "start",
                  //height: "100vh",
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >


                  <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", color: "#29008B" }}>
                    MarketPlace is on its way.
                  </Typography>
                  <Typography sx={{ mb: 4, color: "#131010" }}>
                    We’re working hard to bring you something amazing. This page is currently under development, but it won’t be long before it’s ready!
                  </Typography>
                  <Typography sx={{ mb: 4, color: "#131010" }}>
                    Stay tuned for upcoming updates and exciting features!
                  </Typography>
                  <Typography sx={{ color: "#9A00A9" }}>Thank you for your patience and support!</Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <img
                    src="assets/images/navbar/Commingsoon.png"
                    alt="Tree Design"
                    style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
                  />
                </Box>
              </Box>

            </div>
          </div>
        </div>

      </main>
      <Footer />
    </>
  );
};

export default MarketPlace;
